<template>
  <n-message-provider v-if="!isMaintenance" :placement="store.state.placement">
    <n-modal-provider>
      <router-view></router-view>
    </n-modal-provider>
  </n-message-provider>
  <div
    v-if="isLoadingApp() && !isMaintenance"
    style="z-index: 99999999999"
    class="fixed inset-0 bg-[#fff] flex"
  >
    <div class="flex flex-col items-center m-auto relative">
      <div
        class="translate_animation left-[20%] absolute -translate-x-[25%] top-0 -translate-y-[calc(100%+4px)]"
      >
        <img
          :src="getIcon('solx_logo_loading')"
          alt=""
          class="w-11 animate-spin animate-infinite animate-duration-[5000ms]"
        />
      </div>
      <div
        class="w-[60%] h-1.5 rounded-full overflow-hidden purple_bg_gradient"
      >
        <!-- <v-progress-linear
          color="black"
          height="6"
          model-value="100"
          striped
        ></v-progress-linear> -->
      </div>
      <div class="text_logo_container">
        <svg viewBox="0 0 960 200">
          <symbol id="s-text">
            <text text-anchor="middle" x="50%" y="80%">SOLX</text>
          </symbol>
          <g class="g-ants">
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <MaintenanceComponent v-if="isMaintenance" />
  <n-modal
    v-if="!isMaintenance"
    v-model:show="store.state.showUpgradeModal"
    :on-mask-click="() => handleCloseUpgradeNotification()"
  >
    <CardContainer
      @click.stop="() => undefined"
      hidden-back-layer
      id="term_of_use"
      class="terms_container rounded-[24px] border_2"
    >
      <header class="term_header">
        <span>{{ translation("_maintenance_title") }}</span>
        <img
          :src="getIcon(isDark() ? 'close_white' : 'close')"
          alt=""
          class="icon_w14_h14"
          @click="handleCloseUpgradeNotification"
        />
      </header>
      <div
        class="term_body"
        v-html="store.state.appSetting?.maintain_noti?.text || '<div></div>'"
      ></div> </CardContainer
  ></n-modal>
</template>

<script lang="ts" setup>
import useSolana from "./hook/useSolana";
import { NMessageProvider, NModalProvider, NModal } from "naive-ui";
import { useStore } from "vuex";
import fetchAppConfigData from "./common/function/fetchAppConfigData";
import fetchAppSettingData from "./common/function/fetchAppSettingData";
import fetchIcons from "./common/function/fetchIcons";
import UAParser from "ua-parser-js";
import { onMounted, onUpdated, ref, watchEffect } from "vue";
import CardContainer from "./components/CardContainer.vue";
import translation from "./common/function/translation";
import getIcon from "./common/function/getIcon";
import isLoadingApp from "./common/function/isLoadingApp";
import { watch } from "vue";
import eruda from "eruda";
import MaintenanceComponent from "./components/MaintenanceComponent.vue";
import isDark from "./common/function/isDark";
import { computed } from "vue";

const isMaintenance = process.env.VUE_APP_IS_MAINTENANCE == "true";
const store = useStore();
//
// onMounted(() => {
//   try {
//     alert("starting");
//     const userAgent = window.navigator.userAgent;
//     console.error(userAgent.toString());
//     let parser = new UAParser(userAgent); // you need to pass the user-agent for nodejs
//     console.log(parser); // {}
//     let parserResults = parser.getResult();
//     console.log(parserResults.browser);
//     alert(parserResults.browser.name);
//     alert(parserResults.browser.version);
//   } catch (err) {
//     console.error(err.message);
//   }
// });

useSolana();
fetchAppSettingData("");
fetchIcons();
const handleCloseUpgradeNotification = () => {
  store.commit("setShowUpgradeModal", false);
  localStorage.setItem(
    "times-upgraded",
    store.state.appSetting?.maintain_noti?.times
  );
};
watch(
  [
    () => store.state.appSetting?.maintain_noti?.text,
    () => store.state.showUpgradeModal,
  ],
  () => {
    const timeout = setTimeout(() => {
      const notiContainer = document.querySelector(".terms_container");
      const uls = Array.from(notiContainer?.querySelectorAll("ul") ?? []);
      const ols = Array.from(notiContainer?.querySelectorAll("ol") ?? []);
      const ul_ol_s = uls.concat(ols);

      ul_ol_s.forEach((e: HTMLElement) => {
        e.style.paddingLeft = "24px";
      });
      clearTimeout(timeout);
    }, 200);
  }
);

const handleSetThemMode = () => {
  const themMode = localStorage.getItem("theme-mode");
  switch (themMode) {
    case "light": {
      store.commit("setThemeMode", "light");
      break;
    }
    case "dark": {
      store.commit("setThemeMode", "dark");
      break;
    }
  }
};
onMounted(() => {
  if (process.env.VUE_APP_NODE_ENV == "development") {
    eruda.init();
  }
  handleSetThemMode();
});

function replaceSolxWithSpan(inputString) {
  return inputString.replace(/\b(solx)\b/gi, (match) => {
    return `<span class="solx_color_adjusted">${match}</span>`;
  });
}

const adjustSolxColor = () => {
  let solxContents: any = document
    ?.querySelector("body")
    ?.querySelectorAll("*");
  if (!solxContents) return;
  solxContents = Array.from(solxContents)?.filter(
    (e: any) =>
      e.innerHTML?.toLowerCase()?.includes("solx") &&
      !e.innerHTML?.toLowerCase()?.includes("<") &&
      !e.innerHTML?.toLowerCase()?.includes("</") &&
      e.className !== "solx_color_adjusted" &&
      e.className !== "not_apply_adjust_color_solx"
  );
  if (solxContents.length <= 0) return;
  solxContents.forEach((e: any) => {
    e.innerHTML = replaceSolxWithSpan(e.innerHTML);
  });
};

onMounted(() => {
  setInterval(() => {
    adjustSolxColor();
  }, 200);
});
</script>

<style scoped>
.term_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.term_body {
  padding-left: 16px;
  font-size: 16px;
}

.term_body span {
  white-space: nowrap;
}
.dark #term_of_use {
  background-color: var(--dark-black-light) !important;
}
.terms_container {
  width: 421px !important;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.dark .term_header span {
  color: var(--text-color-dark-mode) !important;
}
.term_header span {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  text-transform: capitalize;
}
.term_footer,
.term_footer > * {
  width: 100%;
}

@media (max-width: 1279px) {
  .term_header span {
    font-size: 18px;
  }
}

.text_logo_container {
  display: flex;
  /*   border:1px solid red; */
  /* height: 100%; */
  align-items: center;
}

svg {
  display: block;
  font: 10.5em "Montserrat";
  width: 100%;
  height: fit-content;
  margin: 0 auto;
}

.text-copy {
  fill: none;
  stroke: white;
  stroke-dasharray: 6% 29%;
  stroke-width: 5px;
  stroke-dashoffset: 0%;
  animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1) {
  stroke: #000;
  animation-delay: -1;
}

.text-copy:nth-child(2) {
  stroke: #000;
  animation-delay: -2s;
}

.text-copy:nth-child(3) {
  stroke: #000;
  animation-delay: -3s;
}

.text-copy:nth-child(4) {
  stroke: #000;
  animation-delay: -4s;
}

.text-copy:nth-child(5) {
  stroke: #000;
  animation-delay: -5s;
}

@keyframes stroke-offset {
  100% {
    stroke-dashoffset: -35%;
  }
}
@keyframes translate_x {
  0% {
    left: 25%;
  }
  100% {
    left: calc(75% - 16px);
  }
}
.translate_animation {
  animation-name: translate_x;
  animation-duration: 8000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>
